import { default as indexPCjH9AATHuMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/dashboard/index.vue?macro=true";
import { default as _91id_930iYCTHXd0IMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measure-groups/[id].vue?macro=true";
import { default as createT58ISyS2xUMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measure-groups/create.vue?macro=true";
import { default as indexkmKhv82rjWMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measure-groups/index.vue?macro=true";
import { default as _91id_93lK1XHAgWdAMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measures/[id].vue?macro=true";
import { default as creategXIkPDmPOtMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measures/create.vue?macro=true";
import { default as indexxfAXiotI4mMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measures/index.vue?macro=true";
import { default as control_45measuresSTog8MKQblMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id]/control-measures.vue?macro=true";
import { default as documents0cyhXMoQKlMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id]/documents.vue?macro=true";
import { default as index48wmJWKvcJMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id]/index.vue?macro=true";
import { default as limitsnlaSGdoYbkMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id]/limits.vue?macro=true";
import { default as risk_45assessmentsRScW6ylFfCMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id]/risk-assessments.vue?macro=true";
import { default as _91id_93Ys2acHdjB6Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id].vue?macro=true";
import { default as create42xxlCTKE6Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/create.vue?macro=true";
import { default as indexLokdwCaIcbMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/index.vue?macro=true";
import { default as indexQBMIqt5Ia1Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/index.vue?macro=true";
import { default as _91id_934pYvoQ4iH2Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/methods/[id].vue?macro=true";
import { default as createUzDgjcehxkMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/methods/create.vue?macro=true";
import { default as indexOrcpaNAKRoMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/methods/index.vue?macro=true";
import { default as _91id_93FFQ9O8LM7AMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/monitoring-groups/[id].vue?macro=true";
import { default as createGQ6IAhDQawMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/monitoring-groups/create.vue?macro=true";
import { default as index4Mq5SMkzxLMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/monitoring-groups/index.vue?macro=true";
import { default as _91id_93pkMKvsOwstMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-step-groups/[id].vue?macro=true";
import { default as createvNPEL5o9cFMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-step-groups/create.vue?macro=true";
import { default as indexzlQbhXfsapMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-step-groups/index.vue?macro=true";
import { default as index3zA785EkdLMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-steps/[id]/index.vue?macro=true";
import { default as risk_45assessmentsy7V9XCHOxSMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-steps/[id]/risk-assessments.vue?macro=true";
import { default as _91id_93cng83OpfV9Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-steps/[id].vue?macro=true";
import { default as createOLIUfV7ImzMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-steps/create.vue?macro=true";
import { default as indexRbuF23685fMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-steps/index.vue?macro=true";
import { default as documentsDLVfmC8PSDMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aid-groups/[id]/documents.vue?macro=true";
import { default as indexDnQx9sSbk8Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aid-groups/[id]/index.vue?macro=true";
import { default as _91id_93lhrgBdNbbkMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aid-groups/[id].vue?macro=true";
import { default as createmj1NiqUMmdMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aid-groups/create.vue?macro=true";
import { default as indexQ9P8l0mzBGMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aid-groups/index.vue?macro=true";
import { default as _91id_93ZWvHwsOumoMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aids/[id].vue?macro=true";
import { default as createlPNOnNrN49Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aids/create.vue?macro=true";
import { default as indexZPMZubN0mHMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aids/index.vue?macro=true";
import { default as _91id_93cER7E39FPxMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/product-groups/[id].vue?macro=true";
import { default as createxDJKRX4fkzMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/product-groups/create.vue?macro=true";
import { default as indexMmZ80HO5smMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/product-groups/index.vue?macro=true";
import { default as _91id_93ZKZmehjxocMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/products/[id].vue?macro=true";
import { default as createyRlhXRDeiBMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/products/create.vue?macro=true";
import { default as indext3ysJe2dQ0Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/products/index.vue?macro=true";
import { default as _91id_93k66HinWWlhMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/units/[id].vue?macro=true";
import { default as createW4EckaQXdOMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/units/create.vue?macro=true";
import { default as indexZqQY6iiQPvMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/units/index.vue?macro=true";
import { default as feed_45materialsUZn5xyqbTWMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/feed-materials.vue?macro=true";
import { default as flowcharts9A1mqF5P8lMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/flowcharts.vue?macro=true";
import { default as hazardsJ7KunVWYd8Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/hazards.vue?macro=true";
import { default as indexpY3YpkZUi0Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/index.vue?macro=true";
import { default as process_45linkingo71sTwnn89Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/process-linking.vue?macro=true";
import { default as process_45stepsZVixu3fYtkMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/process-steps.vue?macro=true";
import { default as processing_45aidsHItW8ETFdlMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/processing-aids.vue?macro=true";
import { default as _91id_93hOSqm7qJeAMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id].vue?macro=true";
import { default as createdsJCglpJ5UMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/create.vue?macro=true";
import { default as feed_45materials_45createWj9mW8nhAyMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/feed-materials-create.vue?macro=true";
import { default as flowcharts_45createoVCL7lZKIcMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/flowcharts-create.vue?macro=true";
import { default as hazards_45createk8raevwHBlMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/hazards-create.vue?macro=true";
import { default as indexItz2XRiKyyMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/index.vue?macro=true";
import { default as process_45linking_45createDUOCa2rO74Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/process-linking-create.vue?macro=true";
import { default as process_45steps_45createEJZDMunwVyMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/process-steps-create.vue?macro=true";
import { default as processing_45aids_45createHv6M6okJw2Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/processing-aids-create.vue?macro=true";
import { default as viewHUmu0dau1GMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/view.vue?macro=true";
import { default as hubNEtVIqkygUMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/hub.vue?macro=true";
import { default as indexY7QphvIeVqMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/index.vue?macro=true";
import { default as indexugxiMp3rciMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/dashboard/index.vue?macro=true";
import { default as tutorialOptionsRLBAWoIAAUMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/dashboard/tutorialOptions.js?macro=true";
import { default as indexIwxaUpQGFPMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/feed-scheme-documents/index.vue?macro=true";
import { default as indexWxtEAHmIz6Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/accepted-feed-materials/[id]/index.vue?macro=true";
import { default as risk_45assessmentsxIfEdsUVDnMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/accepted-feed-materials/[id]/risk-assessments.vue?macro=true";
import { default as _91id_93722jlAyWlwMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/accepted-feed-materials/[id].vue?macro=true";
import { default as index7gp6OtVSsSMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/accepted-feed-materials/index.vue?macro=true";
import { default as indexnp48ENHDF6Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/hazard-tools/report/index.vue?macro=true";
import { default as viewIChAomTJCVMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/hazard-tools/report/view.vue?macro=true";
import { default as indexn859Sm74ZKMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets/[id]/index.vue?macro=true";
import { default as undesirable_45substancesqZT7s7wivOMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets/[id]/undesirable-substances.vue?macro=true";
import { default as _91id_93URLHBnjbRSMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets/[id].vue?macro=true";
import { default as createxSqESi8idNMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets/create.vue?macro=true";
import { default as indexLfbYA4jWzzMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets/index.vue?macro=true";
import { default as analysis_45setsAN7RNM5cKEMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets.vue?macro=true";
import { default as country_45origin7D37t5GCM0Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/country-origin.vue?macro=true";
import { default as index0CeRcUr78IMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/index.vue?macro=true";
import { default as method1nznTQYdkXMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/method.vue?macro=true";
import { default as parameterWHIKi015wqMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/parameter.vue?macro=true";
import { default as producerRiu8r9PfL7Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/producer.vue?macro=true";
import { default as productB0jXjF1tGNMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/product.vue?macro=true";
import { default as indexyp4W4BRAsSMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index.vue?macro=true";
import { default as create_45memberXOwYavbeHxMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/management-group-members/create-member.vue?macro=true";
import { default as indexhvOGOz1ufKMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/management-group-members/index.vue?macro=true";
import { default as indexihZHhRjx8LMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-groups/index.vue?macro=true";
import { default as analysis_45reportjrFFP1tFMPMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/copied-sample/[id]/analysis-report.vue?macro=true";
import { default as indextDdHkJG2KCMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/copied-sample/[id]/index.vue?macro=true";
import { default as tracing_45datajQIrgP3DUWMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/copied-sample/[id]/tracing-data.vue?macro=true";
import { default as _91id_935KtzGpSyqPMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/copied-sample/[id].vue?macro=true";
import { default as copied_45sampleyaIPLeOfYuMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/copied-sample.vue?macro=true";
import { default as _91id_93LxczKf2B9fMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/incoming-sample/[id].vue?macro=true";
import { default as indextkRR2px17jMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample/[id]/index.vue?macro=true";
import { default as undesirable_45substancesU2PSz8kiVVMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample/[id]/undesirable-substances.vue?macro=true";
import { default as _91id_93752zXfcdUJMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample/[id].vue?macro=true";
import { default as create_45samplenBN45FW7WPMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample/create-sample.vue?macro=true";
import { default as createbs36ivuC3IMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample/create.vue?macro=true";
import { default as planned_45sample0Irb5V6CqLMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample.vue?macro=true";
import { default as analysis_45report8EUXfSjG9nMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/[id]/analysis-report.vue?macro=true";
import { default as indexamBbNKKTZrMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/[id]/index.vue?macro=true";
import { default as tracing_45dataSaZoY9DYKLMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/[id]/tracing-data.vue?macro=true";
import { default as _91id_93GnWoOLaJM2Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/[id].vue?macro=true";
import { default as analysis_45report_45createrOMqpe5apsMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/analysis-report-create.vue?macro=true";
import { default as createlm59Q2UidiMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/create.vue?macro=true";
import { default as tracing_45data_45createIQ3PpXqWImMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/tracing-data-create.vue?macro=true";
import { default as samplebS72zmKFqfMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample.vue?macro=true";
import { default as indexDk9OgIzc4LMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/index.vue?macro=true";
import { default as monitoring_45database8vqnn0OXyLMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database.vue?macro=true";
import { default as indexWWAcjlpjx6Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/processing-aids/[id]/index.vue?macro=true";
import { default as risk_45assessmentsuWlcpyxuCvMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/processing-aids/[id]/risk-assessments.vue?macro=true";
import { default as _91id_93Js2DW6GQnTMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/processing-aids/[id].vue?macro=true";
import { default as indexxRuEG8n2D0Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/processing-aids/index.vue?macro=true";
import { default as index2a4d8DwxzBMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/risk-assessments/index.vue?macro=true";
import { default as viewJdbeSNughnMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/risk-assessments/view.vue?macro=true";
import { default as control_45measuresf74Bb1usWnMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/[id]/control-measures.vue?macro=true";
import { default as indexExLflVL5KCMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/[id]/index.vue?macro=true";
import { default as limitsNR59iEy0LfMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/[id]/limits.vue?macro=true";
import { default as risk_45assessmentsGPBLosRAt7Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/[id]/risk-assessments.vue?macro=true";
import { default as _91id_93euqWWTYY6JMeta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/[id].vue?macro=true";
import { default as index1QshJSsO68Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/index.vue?macro=true";
import { default as risk_45managementoVywB4ITk0Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management.vue?macro=true";
import { default as portalLpo6qQAzu9Meta } from "/home/vsts/work/1/s/src/gmp-swa/pages/portal.vue?macro=true";
export default [
  {
    name: "hub",
    path: "/hub",
    meta: hubNEtVIqkygUMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub.vue").then(m => m.default || m),
    children: [
  {
    name: "hub-dashboard",
    path: "dashboard",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-control-measure-groups-id",
    path: "management/control-measure-groups/:id()",
    meta: _91id_930iYCTHXd0IMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measure-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "hub-management-control-measure-groups-create",
    path: "management/control-measure-groups/create",
    meta: createT58ISyS2xUMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measure-groups/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-control-measure-groups",
    path: "management/control-measure-groups",
    meta: indexkmKhv82rjWMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measure-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-control-measures-id",
    path: "management/control-measures/:id()",
    meta: _91id_93lK1XHAgWdAMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measures/[id].vue").then(m => m.default || m)
  },
  {
    name: "hub-management-control-measures-create",
    path: "management/control-measures/create",
    meta: creategXIkPDmPOtMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measures/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-control-measures",
    path: "management/control-measures",
    meta: indexxfAXiotI4mMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/control-measures/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Ys2acHdjB6Meta?.name,
    path: "management/hazards/:id()",
    meta: _91id_93Ys2acHdjB6Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "hub-management-hazards-id-control-measures",
    path: "control-measures",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id]/control-measures.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-hazards-id-documents",
    path: "documents",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id]/documents.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-hazards-id",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-hazards-id-limits",
    path: "limits",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id]/limits.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-hazards-id-risk-assessments",
    path: "risk-assessments",
    meta: risk_45assessmentsRScW6ylFfCMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/[id]/risk-assessments.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hub-management-hazards-create",
    path: "management/hazards/create",
    meta: create42xxlCTKE6Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-hazards",
    path: "management/hazards",
    meta: indexLokdwCaIcbMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/hazards/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management",
    path: "management",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-methods-id",
    path: "management/methods/:id()",
    meta: _91id_934pYvoQ4iH2Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/methods/[id].vue").then(m => m.default || m)
  },
  {
    name: "hub-management-methods-create",
    path: "management/methods/create",
    meta: createUzDgjcehxkMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/methods/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-methods",
    path: "management/methods",
    meta: indexOrcpaNAKRoMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/methods/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-monitoring-groups-id",
    path: "management/monitoring-groups/:id()",
    meta: _91id_93FFQ9O8LM7AMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/monitoring-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "hub-management-monitoring-groups-create",
    path: "management/monitoring-groups/create",
    meta: createGQ6IAhDQawMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/monitoring-groups/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-monitoring-groups",
    path: "management/monitoring-groups",
    meta: index4Mq5SMkzxLMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/monitoring-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-process-step-groups-id",
    path: "management/process-step-groups/:id()",
    meta: _91id_93pkMKvsOwstMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-step-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "hub-management-process-step-groups-create",
    path: "management/process-step-groups/create",
    meta: createvNPEL5o9cFMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-step-groups/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-process-step-groups",
    path: "management/process-step-groups",
    meta: indexzlQbhXfsapMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-step-groups/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93cng83OpfV9Meta?.name,
    path: "management/process-steps/:id()",
    meta: _91id_93cng83OpfV9Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-steps/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "hub-management-process-steps-id",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-steps/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-process-steps-id-risk-assessments",
    path: "risk-assessments",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-steps/[id]/risk-assessments.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hub-management-process-steps-create",
    path: "management/process-steps/create",
    meta: createOLIUfV7ImzMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-steps/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-process-steps",
    path: "management/process-steps",
    meta: indexRbuF23685fMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/process-steps/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lhrgBdNbbkMeta?.name,
    path: "management/processing-aid-groups/:id()",
    meta: _91id_93lhrgBdNbbkMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aid-groups/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "hub-management-processing-aid-groups-id-documents",
    path: "documents",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aid-groups/[id]/documents.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-processing-aid-groups-id",
    path: "",
    meta: indexDnQx9sSbk8Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aid-groups/[id]/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hub-management-processing-aid-groups-create",
    path: "management/processing-aid-groups/create",
    meta: createmj1NiqUMmdMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aid-groups/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-processing-aid-groups",
    path: "management/processing-aid-groups",
    meta: indexQ9P8l0mzBGMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aid-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-processing-aids-id",
    path: "management/processing-aids/:id()",
    meta: _91id_93ZWvHwsOumoMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aids/[id].vue").then(m => m.default || m)
  },
  {
    name: "hub-management-processing-aids-create",
    path: "management/processing-aids/create",
    meta: createlPNOnNrN49Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aids/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-processing-aids",
    path: "management/processing-aids",
    meta: indexZPMZubN0mHMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/processing-aids/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-product-groups-id",
    path: "management/product-groups/:id()",
    meta: _91id_93cER7E39FPxMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/product-groups/[id].vue").then(m => m.default || m)
  },
  {
    name: "hub-management-product-groups-create",
    path: "management/product-groups/create",
    meta: createxDJKRX4fkzMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/product-groups/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-product-groups",
    path: "management/product-groups",
    meta: indexMmZ80HO5smMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/product-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-products-id",
    path: "management/products/:id()",
    meta: _91id_93ZKZmehjxocMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/products/[id].vue").then(m => m.default || m)
  },
  {
    name: "hub-management-products-create",
    path: "management/products/create",
    meta: createyRlhXRDeiBMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/products/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-products",
    path: "management/products",
    meta: indext3ysJe2dQ0Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/products/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-units-id",
    path: "management/units/:id()",
    meta: _91id_93k66HinWWlhMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/units/[id].vue").then(m => m.default || m)
  },
  {
    name: "hub-management-units-create",
    path: "management/units/create",
    meta: createW4EckaQXdOMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/units/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-management-units",
    path: "management/units",
    meta: indexZqQY6iiQPvMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/management/units/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93hOSqm7qJeAMeta?.name,
    path: "risk-assessments/:id()",
    meta: _91id_93hOSqm7qJeAMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "hub-risk-assessments-id-feed-materials",
    path: "feed-materials",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/feed-materials.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-id-flowcharts",
    path: "flowcharts",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/flowcharts.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-id-hazards",
    path: "hazards",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/hazards.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-id",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-id-process-linking",
    path: "process-linking",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/process-linking.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-id-process-steps",
    path: "process-steps",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/process-steps.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-id-processing-aids",
    path: "processing-aids",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/[id]/processing-aids.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "hub-risk-assessments-create",
    path: "risk-assessments/create",
    meta: createdsJCglpJ5UMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/create.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-feed-materials-create",
    path: "risk-assessments/feed-materials-create",
    meta: feed_45materials_45createWj9mW8nhAyMeta || {},
    alias: ["/hub/risk-assessments/:id/feed-materials/create"],
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/feed-materials-create.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-flowcharts-create",
    path: "/hub/risk-assessments/:id/flowcharts/create",
    meta: flowcharts_45createoVCL7lZKIcMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/flowcharts-create.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-hazards-create",
    path: "/hub/risk-assessments/:id/hazards/create",
    meta: hazards_45createk8raevwHBlMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/hazards-create.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments",
    path: "risk-assessments",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/index.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-process-linking-create",
    path: "/hub/risk-assessments/:id/process-linking/create",
    meta: process_45linking_45createDUOCa2rO74Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/process-linking-create.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-process-steps-create",
    path: "/hub/risk-assessments/:id/process-steps/create",
    meta: process_45steps_45createEJZDMunwVyMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/process-steps-create.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-processing-aids-create",
    path: "/hub/risk-assessments/:id/processing-aids/create",
    meta: processing_45aids_45createHv6M6okJw2Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/processing-aids-create.vue").then(m => m.default || m)
  },
  {
    name: "hub-risk-assessments-view",
    path: "/hub/risk-assessments/:id/view",
    meta: viewHUmu0dau1GMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/hub/risk-assessments/view.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "portal",
    path: "/portal",
    meta: portalLpo6qQAzu9Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal.vue").then(m => m.default || m),
    children: [
  {
    name: "portal-dashboard",
    path: "dashboard",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-dashboard-tutorialOptions",
    path: "dashboard/tutorialOptions",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/dashboard/tutorialOptions.js").then(m => m.default || m)
  },
  {
    name: "portal-feed-scheme-documents",
    path: "feed-scheme-documents",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/feed-scheme-documents/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management",
    path: "risk-management",
    meta: risk_45managementoVywB4ITk0Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93722jlAyWlwMeta?.name,
    path: "accepted-feed-materials/:id()",
    meta: _91id_93722jlAyWlwMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/accepted-feed-materials/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "portal-risk-management-accepted-feed-materials-id",
    path: "",
    meta: indexWxtEAHmIz6Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/accepted-feed-materials/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-accepted-feed-materials-id-risk-assessments",
    path: "risk-assessments",
    meta: risk_45assessmentsxIfEdsUVDnMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/accepted-feed-materials/[id]/risk-assessments.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portal-risk-management-accepted-feed-materials",
    path: "accepted-feed-materials",
    meta: index7gp6OtVSsSMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/accepted-feed-materials/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-hazard-tools-report",
    path: "hazard-tools/report",
    meta: indexnp48ENHDF6Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/hazard-tools/report/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-hazard-tools-report-view",
    path: "/portal/risk-management/hazard-tools/report/view",
    meta: viewIChAomTJCVMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/hazard-tools/report/view.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database",
    path: "monitoring-database",
    meta: monitoring_45database8vqnn0OXyLMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database.vue").then(m => m.default || m),
    children: [
  {
    name: analysis_45setsAN7RNM5cKEMeta?.name,
    path: "analysis-sets",
    meta: analysis_45setsAN7RNM5cKEMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93URLHBnjbRSMeta?.name,
    path: ":id()",
    meta: _91id_93URLHBnjbRSMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "portal-risk-management-monitoring-database-analysis-sets-id",
    path: "",
    meta: indexn859Sm74ZKMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-analysis-sets-id-undesirable-substances",
    path: "undesirable-substances",
    meta: undesirable_45substancesqZT7s7wivOMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets/[id]/undesirable-substances.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portal-risk-management-monitoring-database-analysis-sets-create",
    path: "create",
    meta: createxSqESi8idNMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets/create.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-analysis-sets",
    path: "",
    meta: indexLfbYA4jWzzMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/analysis-sets/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexyp4W4BRAsSMeta?.name,
    path: "code-lists",
    meta: indexyp4W4BRAsSMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index.vue").then(m => m.default || m),
    children: [
  {
    name: "portal-risk-management-monitoring-database-code-lists-index-country-origin",
    path: "country-origin",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/country-origin.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-code-lists-index",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-code-lists-index-method",
    path: "method",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/method.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-code-lists-index-parameter",
    path: "parameter",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/parameter.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-code-lists-index-producer",
    path: "producer",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/producer.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-code-lists-index-product",
    path: "product",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/code-lists/index/product.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portal-risk-management-monitoring-database-management-group-members-create-member",
    path: "/portal/risk-management/monitoring-database/management-group-members/create",
    meta: create_45memberXOwYavbeHxMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/management-group-members/create-member.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-management-group-members",
    path: "management-group-members",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/management-group-members/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-groups",
    path: "my-groups",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-groups/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-copied-sample",
    path: "my-monitoring/:locationId()/copied-sample",
    meta: copied_45sampleyaIPLeOfYuMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/copied-sample.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_935KtzGpSyqPMeta?.name,
    path: ":id()",
    meta: _91id_935KtzGpSyqPMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/copied-sample/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-copied-sample-id-analysis-report",
    path: "analysis-report",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/copied-sample/[id]/analysis-report.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-copied-sample-id",
    path: "",
    meta: indextDdHkJG2KCMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/copied-sample/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-copied-sample-id-tracing-data",
    path: "tracing-data",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/copied-sample/[id]/tracing-data.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-incoming-sample-id",
    path: "my-monitoring/:locationId()/incoming-sample/:id()",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/incoming-sample/[id].vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-planned-sample",
    path: "my-monitoring/:locationId()/planned-sample",
    meta: planned_45sample0Irb5V6CqLMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93752zXfcdUJMeta?.name,
    path: ":id()",
    meta: _91id_93752zXfcdUJMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-planned-sample-id",
    path: "",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-planned-sample-id-undesirable-substances",
    path: "undesirable-substances",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample/[id]/undesirable-substances.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-planned-sample-create-sample",
    path: "create-sample",
    meta: create_45samplenBN45FW7WPMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample/create-sample.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-planned-sample-create",
    path: "create",
    meta: createbs36ivuC3IMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/planned-sample/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-sample",
    path: "my-monitoring/:locationId()/sample",
    meta: samplebS72zmKFqfMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93GnWoOLaJM2Meta?.name,
    path: ":id()",
    meta: _91id_93GnWoOLaJM2Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-sample-id-analysis-report",
    path: "analysis-report",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/[id]/analysis-report.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-sample-id",
    path: "",
    meta: indexamBbNKKTZrMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-sample-id-tracing-data",
    path: "tracing-data",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/[id]/tracing-data.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-sample-analysis-report-create",
    path: "/portal/risk-management/monitoring-database/my-monitoring/:locationId/sample/:id/analysis-report/create",
    meta: analysis_45report_45createrOMqpe5apsMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/analysis-report-create.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-sample-create",
    path: "create",
    meta: createlm59Q2UidiMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/create.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring-locationId-sample-tracing-data-create",
    path: "/portal/risk-management/monitoring-database/my-monitoring/:locationId/sample/:id/tracing-data/create",
    meta: tracing_45data_45createIQ3PpXqWImMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/[locationId]/sample/tracing-data-create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portal-risk-management-monitoring-database-my-monitoring",
    path: "my-monitoring",
    meta: indexDk9OgIzc4LMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/monitoring-database/my-monitoring/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93Js2DW6GQnTMeta?.name,
    path: "processing-aids/:id()",
    meta: _91id_93Js2DW6GQnTMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/processing-aids/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "portal-risk-management-processing-aids-id",
    path: "",
    meta: indexWWAcjlpjx6Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/processing-aids/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-processing-aids-id-risk-assessments",
    path: "risk-assessments",
    meta: risk_45assessmentsuWlcpyxuCvMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/processing-aids/[id]/risk-assessments.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portal-risk-management-processing-aids",
    path: "processing-aids",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/processing-aids/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-risk-assessments",
    path: "risk-assessments",
    meta: index2a4d8DwxzBMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/risk-assessments/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-risk-assessments-view",
    path: "/portal/risk-management/risk-assessments/:id/view",
    meta: viewJdbeSNughnMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/risk-assessments/view.vue").then(m => m.default || m)
  },
  {
    name: _91id_93euqWWTYY6JMeta?.name,
    path: "undesirable-substances/:id()",
    meta: _91id_93euqWWTYY6JMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "portal-risk-management-undesirable-substances-id-control-measures",
    path: "control-measures",
    meta: control_45measuresf74Bb1usWnMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/[id]/control-measures.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-undesirable-substances-id",
    path: "",
    meta: indexExLflVL5KCMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-undesirable-substances-id-limits",
    path: "limits",
    meta: limitsNR59iEy0LfMeta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/[id]/limits.vue").then(m => m.default || m)
  },
  {
    name: "portal-risk-management-undesirable-substances-id-risk-assessments",
    path: "risk-assessments",
    meta: risk_45assessmentsGPBLosRAt7Meta || {},
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/[id]/risk-assessments.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "portal-risk-management-undesirable-substances",
    path: "undesirable-substances",
    component: () => import("/home/vsts/work/1/s/src/gmp-swa/pages/portal/risk-management/undesirable-substances/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]